import React from 'react'
import Layout from '@components/Layout'
import { Seo } from '@components/Seo'
import { graphql } from 'gatsby'
import PageTitle from '@components/PageTitle'
import { Article, Content } from '@global'
import { Eventdate } from './styles'

const EventsTemplate = ({ data }) => {
  const events = data.datoCmsEvent
  const seotitle = events.title + ' | In Sowerby Bridge The Town and Business Guide For Residents and Visitors'

  return (
    <Layout>
      <Article>
        <Seo title={seotitle} />
        <PageTitle title={events.title} />
        <Eventdate>{events.displaydate}</Eventdate>
        <Content
          dangerouslySetInnerHTML={{
            __html: events.contentNode.childMarkdownRemark.html,
          }}
        />
        {/* <Image>{events.eventimage && <Img alt={events.title} fluid={events.eventimage.localFiles[0].childImageSharp.fluid} />}</Image> */}
      </Article>
    </Layout>
  )
}

export const aireventgraph = graphql`
  query aireventquery($slug: String!) {
    datoCmsEvent(slug: { eq: $slug }) {
      id
      title
      displaydate
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default EventsTemplate
